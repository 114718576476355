import { config, dsConfig } from "../settings"

export function githubOpenAPISpecUrl(definition) {
  if (!definition) {
    return ""
  }

  return `${config.definitions}/${definition}.json`
}

export function definitionsViewerUrl(definition) {
  if (!definition || !dsConfig.components["Data Definitions"]) {
    return ""
  }

  const base = `${dsConfig.components["Data Definitions"]}/definitions`
  return `${base}/${definition}`
}

export function swaggerUIUrl(definition) {
  if (!definition) {
    return ""
  }

  const base = `${config.productGateway}/docs#/Data%20Products`
  const path = definition.replaceAll(/[/.]/g, "_")
  return `${base}/${path}_${path}_post` // Strange but true
}

export function apiDocsUrl(definition) {
  if (!definition || !dsConfig.components["Data Definitions"]) {
    return ""
  }

  const base = `${dsConfig.components["API Docs"]}/gateway#tag/Data-Products/operation`
  const path = definition.replaceAll(/[/.]/g, "_")
  return `${base}/${path}_${path}_post` // Again, strange but true
}
