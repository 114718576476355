import React from "react"
import ReactDOM from "react-dom"

import { ThemeProvider } from "@ioxio-priv/dataspace-ui"
import { CssBaseline } from "@mui/material"
import GlobalStyles from "@mui/material/GlobalStyles"

import App from "./App"
import globalStyles from "./globalStyles"
import * as serviceWorker from "./serviceWorker"
import { config } from "./settings"

import "@fontsource/poppins/100.css"
import "@fontsource/poppins/200.css"
import "@fontsource/poppins/300.css"
import "@fontsource/poppins/400.css"
import "@fontsource/poppins/500.css"
import "@fontsource/poppins/600.css"
import "@fontsource/poppins/700.css"
import "@fontsource/poppins/800.css"
import "@fontsource/poppins/900.css"
import "@fontsource/poppins/100-italic.css"
import "@fontsource/poppins/200-italic.css"
import "@fontsource/poppins/300-italic.css"
import "@fontsource/poppins/400-italic.css"
import "@fontsource/poppins/500-italic.css"
import "@fontsource/poppins/600-italic.css"
import "@fontsource/poppins/700-italic.css"
import "@fontsource/poppins/800-italic.css"
import "@fontsource/poppins/900-italic.css"

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={config.theme}>
      <CssBaseline />
      <GlobalStyles styles={globalStyles} />
      <App />
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById("root")
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
