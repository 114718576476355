import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { toast } from "react-toastify"

import { styled } from "@ioxio-priv/dataspace-ui"

import EditDataSourceForm from "@/components/EditDataSourceForm"
import AllowedGroups from "@/components/EditDataSourceForm/AllowedGroups"
import RemoveFormModal from "@/components/RemoveForm"
import { BreadCrumbsNames } from "@/constants/breadcrumbs"
import { labels } from "@/constants/labels"
import ROUTES from "@/constants/routes"
import InitialLoading from "@/containers/InitialLoading"
import SourcesWrapper from "@/containers/SourcesWrapper"
import useLayoutOpts from "@/hooks/useLayoutOpts"
import useToast from "@/hooks/useToast"
import DataSourceAPI from "@/services/dataSourceAPI"
import { makeDSI } from "@/utilities"

export default function SourcesEdit({ location, history }) {
  const { MY_SOURCES } = ROUTES
  const [dataSource, setDataSource] = useState()
  const [loading, setLoading] = useState(true)
  const [isRemoveFormShown, setRemoveFormShown] = useState(false)
  const { definition, source } = useParams()
  const [dsi, setDsi] = useState("")

  //render navbar and footer
  useLayoutOpts(true, true)

  // fetch data source to edit
  useEffect(() => {
    async function fetchDataSource() {
      setLoading(true)
      const { ok, data } = await DataSourceAPI.getDataSource(definition, source)
      if (ok) {
        setDataSource(data)
        setDsi(makeDSI({ ...data }))
        setLoading(false)
      } else {
        await history.push({
          pathname: MY_SOURCES,
          state: {
            error: "Data source not found. Please try again.",
          },
        })
      }
    }
    fetchDataSource()
  }, [history])

  // Initialize toasts and render any in the state
  useToast(location.state)

  async function editDataSource(payload, handleErrors) {
    const { ok, data, error } = await DataSourceAPI.updateSource(payload)
    if (ok) {
      await history.push({
        pathname: MY_SOURCES,
        state: {
          success: `Successfully updated data source ${data.source}`,
        },
      })
    } else {
      handleErrors(error)
    }
    return { ok }
  }

  async function onDelete() {
    const { ok, error } = await DataSourceAPI.deleteSource({ source, definition })
    if (ok) {
      await history.push({
        pathname: MY_SOURCES,
        state: { success: `Successfully deleted data source ${source}` },
      })
    } else {
      toast.error(error)
    }
    return { ok }
  }

  return !loading && dataSource ? (
    <SourcesWrapper
      breadCrumbs={BreadCrumbsNames.EDIT_SOURCES}
      meta={labels.meta.editSources}
      editPage={true}
      title={"Edit data source"}
      belowHeader={
        <DescriptionText {...{ "data-testid": definition }}>
          {definition}
        </DescriptionText>
      }
    >
      <EditDataSourceForm
        definition={definition}
        source={source}
        isRemoveFormShown={isRemoveFormShown}
        setRemoveFormShown={setRemoveFormShown}
        initialFormValues={{
          previewToken: dataSource.previewToken,
          baseUrl: dataSource.sourceUrl,
          state: dataSource.state,
          accessControlMode: dataSource.accessControlMode,
        }}
        asyncOnSubmit={editDataSource}
      />
      {dataSource.accessControlMode !== "none" && <AllowedGroups dsi={dsi} />}
      <RemoveFormModal
        isOpen={isRemoveFormShown}
        setIsOpen={setRemoveFormShown}
        name={source}
        type="data source"
        asyncOnSubmit={onDelete}
        title={`Delete data source ${source}?`}
      />
    </SourcesWrapper>
  ) : (
    <InitialLoading />
  )
}

const DescriptionText = styled("div")`
  font-size: 1.125rem;
  line-height: 150%;
  font-weight: 600;
  margin-bottom: 1.5rem;
`
