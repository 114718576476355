export default function styles(theme) {
  return {
    html: {
      width: "100%",
      height: "100%",
    },
    body: {
      width: "100%",
      height: "100%",
    },
    "#root": {
      display: "contents",
    },
    p: {
      margin: 0,
    },
    hr: {
      margin: 0,
    },
    //https://fkhadra.github.io/react-toastify/how-to-style/
    ".Toastify__toast-body": {
      wordBreak: "break-word",
    },
    ".Toastify__toast--success": {
      background: theme.palette.success.main,
    },
    ".Toastify__toast--error": {
      background: theme.palette.error.main,
    },
    ".Toastify__toast--warning": {
      background: theme.palette.warning.main,
      color: theme.palette.text.primary,
    },
    ".Toastify__toast--info": {
      background: theme.palette.info.light,
      color: theme.palette.text.primary,
    },
  }
}
