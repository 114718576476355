import API from "./API"

class DataSourceAPI extends API {
  async create(data) {
    return await this.makeRequest(
      `${this.baseUrl}/v2/sources/${data.definition}/${data.source}`,
      {
        method: "POST",
        headers: this.headers,
        body: JSON.stringify(data),
      }
    )
  }

  async deleteSource(data) {
    return await this.makeRequest(
      `${this.baseUrl}/v2/sources/${data.definition}/${data.source}`,
      {
        method: "DELETE",
        headers: this.headers,
      }
    )
  }

  async getDataSource(definition, source) {
    return await this.makeRequest(
      `${this.baseUrl}/v2/sources/${definition}/${source}`,
      {
        method: "GET",
        headers: this.headers,
      }
    )
  }

  async getMySources() {
    return await this.makeRequest(`${this.baseUrl}/v2/sources/my-sources`, {
      method: "GET",
      headers: this.headers,
    })
  }

  async getDataSourceDefinitions() {
    return await this.makeRequest(`${this.baseUrl}/v3/data-source-definitions`, {
      method: "GET",
      headers: this.headers,
    })
  }
  async getAvailableDataSources(definition) {
    return await this.makeRequest(
      `${this.baseUrl}/v2/sources/published/${definition}`,
      {
        method: "GET",
        headers: this.headers,
      }
    )
  }

  async updateSource(data) {
    return await this.makeRequest(
      `${this.baseUrl}/v2/sources/${data.definition}/${data.source}`,
      {
        method: "PUT",
        headers: this.headers,
        body: JSON.stringify(data),
      }
    )
  }

  async refreshPreviewToken(data) {
    return await this.makeRequest(`${this.baseUrl}/v1/sources/refresh-preview-token`, {
      method: "POST",
      headers: this.headers,
      body: JSON.stringify(data),
    })
  }

  async listAllowedGroups(dsi) {
    let url = `${this.baseUrl}/v2/sources/allowed-groups/?`
    url += new URLSearchParams({ dsi }).toString()
    return await this.makeRequest(url, {
      method: "GET",
      headers: this.headers,
    })
  }

  async addAllowedGroup(data) {
    return await this.makeRequest(`${this.baseUrl}/v2/sources/allowed-groups/`, {
      method: "POST",
      headers: this.headers,
      body: JSON.stringify(data),
    })
  }

  async removeAllowedGroup(dsi, group) {
    let url = `${this.baseUrl}/v2/sources/allowed-groups/?`
    url += new URLSearchParams({ dsi, group }).toString()
    return await this.makeRequest(url, {
      method: "DELETE",
      headers: this.headers,
    })
  }

  async searchDataSources(data) {
    return await this.makeRequest(`${this.baseUrl}/v2/sources/search`, {
      method: "POST",
      headers: this.headers,
      body: JSON.stringify(data),
    })
  }
}

const instance = new DataSourceAPI()

export default instance
