import { defaultTheme } from "@ioxio-priv/dataspace-ui"
import { getRuntimeSettings } from "@ioxio-priv/dataspace-utils"

const runtimeSettings = getRuntimeSettings()
const ENV = runtimeSettings.ENV || "development"
const dataspaceDomain = runtimeSettings.DATASPACE_BASE_DOMAIN
export function isEnabledFromEnv(name) {
  const value = import.meta.env[`VITE_FEATURE_${name.toUpperCase()}`]
  if (value) {
    return value === "1"
  }
  return undefined
}

// Dataspace specific config
export const baseDsConfig = {
  baseUrl: runtimeSettings.DEVELOPER_PORTAL_URL,
  components: {
    "API Docs": runtimeSettings.DATASPACE_DOCS_URL,
    // Some dataspaces can not have data definitions viewer
    "Data Definitions": runtimeSettings.DEFINITIONS_VIEWER_URL,
    "Developer Portal": runtimeSettings.DEVELOPER_PORTAL_URL,
  },
  navFooterLinks: {
    Privacy: `https://${dataspaceDomain}/privacy`,
    Legal: `https://${dataspaceDomain}/terms-of-service`,
  },
  dataspaceVariant: "IOXIO Dataspace Sandbox",
  dataspaceHome: `https://${dataspaceDomain}`,
  footerExternalLinks: [
    { name: "IOXIO", url: "https://ioxio.com" },
    { name: "IOXIO Dataspace Sandbox", url: "https://sandbox.ioxio-dataspace.com" },
    { name: "GitHub", url: "https://github.com/ioxio-dataspace" },
  ],
  whitelabel: false,
}

// Developer Portal specific config
export const baseConfig = {
  features: {
    groupInvitations: ["development", "test"].includes(ENV),
  },
  sourcesRepo: "https://github.com/ioxio-dataspace/sandbox-definitions",
  definitions:
    "https://github.com/ioxio-dataspace/sandbox-definitions/tree/main/DataProducts",
  termsOfService: "https://sandbox.ioxio-dataspace.com/terms-of-service",
  termsOfServiceName: "Terms of Service",
  inviteOnly: runtimeSettings.INVITE_ONLY,
  supportEmail: "support@ioxio.com",
  privacyPolicy: "https://sandbox.ioxio-dataspace.com/privacy/",
  productGateway: runtimeSettings.PRODUCT_GATEWAY_URL,
  dataspaceDomain: dataspaceDomain,
  dataspaceWebsite: `https://${dataspaceDomain}`,
  jwksUrl: `https://${dataspaceDomain}/.well-known/jwks.json`,
  productizationGuide: "https://miro.com/app/board/o9J_lbLav_s=/",
  exampleProductizer: "https://github.com/ioxio-dataspace/example-productizer",
  exampleApplication: "https://github.com/ioxio-dataspace/example-app",
  partyConfigurationDocs: "https://docs.ioxio.dev/schemas/party-configuration/",
  disableApps: false,
  externalAppManagement: false,
  externalAppSettings: {},
  theme: defaultTheme,
  guideDataSources: "https://miro.com/app/board/uXjVO7VckHk=/",
  guideApplications: "https://miro.com/app/board/uXjVO7Utac8=/",
  apiTokenValidityTime: 60 * 60 * 1000, // ms

  developerResources: {
    title: "Developer resources",
    description: "Resources on how to build, integrate and innovate on Dataspaces.",
    sections: [
      {
        title: "Guides",
        description:
          "Tutorials on how to build on and integrate using our Dataspace technology.",
        icon: "basic-guides",
        links: [
          {
            label: "How to create a group",
            href: "https://ioxio.com/guides/how-to-create-a-group",
          },
          {
            label: "Verify consent in a data source",
            href: "https://ioxio.com/guides/verify-consent-in-a-data-source",
          },
          {
            label: "How to create data definitions",
            href: "https://ioxio.com/guides/how-to-create-data-definitions",
          },
          {
            label: "How to build a data source",
            href: "https://ioxio.com/guides/how-to-build-a-data-source",
          },
          {
            label: "Verify id_token in a data source",
            href: "https://ioxio.com/guides/verify-id-token-in-a-data-source",
          },
        ],
      },
      {
        title: "APIs",
        icon: "api-guides",
        description:
          "We offer API docs in multiple formats to ensure they are readable for anyone, no matter their background.",
        links: [
          {
            label: "Dataspace API documentation",
            href: "https://docs.sandbox.ioxio-dataspace.com/",
          },
          {
            label: "Product Gateway - Swagger UI",
            href: "https://gateway.sandbox.ioxio-dataspace.com/docs",
          },
          {
            label: "Data definition viewer",
            href: "https://definitions.sandbox.ioxio-dataspace.com/",
          },
        ],
      },
      {
        title: "Video Guides",
        icon: "video-guides",
        description:
          "If you prefer to learn by watching and listening check out our videos below.",
        links: [
          {
            label: "Defining Data Products",
            href: "https://www.youtube.com/watch?v=yPzN04ICsbw&t=194s",
          },
          {
            label: "Productizing data",
            href: "https://www.youtube.com/watch?v=f-f6P_-8zoQ",
          },
        ],
      },
      {
        title: "Other resources",
        icon: "other-guides",
        description: "Other available resources.",
        links: [
          {
            label: "IOXIO Dataspace GitHub",
            href: "https://github.com/ioxio-dataspace",
          },
          {
            label: "Party configuration schema",
            href: "https://docs.ioxio.dev/schemas/party-configuration/",
          },
          {
            label: "Consent Request Token schema",
            href: "https://docs.ioxio.dev/schemas/consent-request-token/",
          },
          {
            label: "Consent Token schema",
            href: "https://docs.ioxio.dev/schemas/consent-token/",
          },
          {
            label: "Dataspace configuration schema",
            href: "https://docs.ioxio.dev/schemas/dataspace-configuration/",
          },
        ],
      },
    ],
  },
}

if (!baseConfig.disableApps) {
  baseConfig.developerResources.sections[0].links.push(
    {
      label: "How to build an application",
      href: "https://ioxio.com/guides/how-to-build-an-application",
    },
    {
      label: "Use login portal in your application",
      href: "https://ioxio.com/guides/use-login-portal-in-your-applications",
    }
  )
}

for (let feature in baseConfig.features) {
  const override = isEnabledFromEnv(feature)
  if (override !== undefined) {
    baseConfig.features[feature] = override
  }
}
